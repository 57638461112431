<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="-0.0078125"
      y="0.668945"
      width="40"
      height="40"
      rx="8"
      fill="#F9FAFB"
    />
    <path
      d="M17.9922 28.6689H21.9922V12.6689H17.9922V28.6689ZM11.9922 28.6689H15.9922V20.6689H11.9922V28.6689ZM23.9922 17.6689V28.6689H27.9922V17.6689H23.9922Z"
      fill="#6366FA"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcCharts',
}
</script>
